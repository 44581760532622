* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: inherit;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit !important;
  font-weight: 750 !important;
  color: #2e3e4f;
}
h4 {
  color: #110f24;
}

div,
p,
li,
td,
th {
  font-family: "Avenir", sans-serif;
  font-weight: normal;
  font-size: 1rem; /* 14px */
}

/* Avenir normal */
@font-face {
  font-family: "Avenir";
  font-weight: 400;
  font-style: normal;
  /* src: local("Avenir"), url(/fonts/AvenirLTStd-Book.otf) format("opentype"); */
}

/* Avenir 600 */
@font-face {
  font-family: "AvenirLTStd-Heavy";
  font-weight: 600;
  font-style: bold;
  /* src: local("Avenir"), url(/fonts/AvenirLTStd-Heavy.otf) format("opentype"); */
}

@font-face {
  font-family: "AvenirLTStd-Black";
  font-weight: 900;
  font-style: bold;
  /* src: local("Avenir"), url(/fonts/AvenirLTStd-Black.otf) format("opentype"); */
}

.la-typo {
  font-family: "AvenirLTStd-Black";
}

.strong {
  font-weight: bold !important;
}

/* TAMAÑOS Y COLORES DE TEXTOS */
.text-large {
  font-size: 1.142rem; /* 16px */
}
.text-medium {
  font-size: 1rem !important; /* 14px */
}
.text-small {
  font-size: 0.857rem !important; /* 12px */
}
/* PARA DESKTOP */
@media only screen and (min-width: 768px) {
  /* DEFINIAR */
}

.text-primary {
  color: #2e3e4f !important;
}
.text-secondary {
  color: #00d1a9 !important;
}
.text-grey {
  color: #6f7271 !important;
}
.text-red {
  color: #c84040 !important;
}
.text-dark {
  color: #0b2823 !important;
}
.text-grey-dark {
  color: #ccced0 !important;
}
.text-grey-medium {
  color: #ececee !important;
}
.text-grey-light {
  color: #f6f6f9 !important;
}

.fw-400 {
  font-weight: 400 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}

.btnSuccess {
  background-color: #00b259 !important;
}
.txtIngress {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #110f24 !important;
}
.form-login {
  width: 350px;
  display: block;
  margin: auto;
  padding-left: 24px;
  padding-right: 24px;
}
.form-register {
  min-width: 768px;
  max-width: 947px;
  background-color: #ffffff;
  padding: 4rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.logoLogin {
  width: 150px;
  display: block;
  margin: auto;
  /* padding-bottom: 50px; */
  z-index: 99;
}
.logonNavBar {
  width: 120px;
  display: block;
  margin: 1.5rem;
  margin-top: 50px;
  margin-bottom: 10px;
}

.header {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 10;
  /* background-color: #ffffff !important; */
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 22px
}
.headers {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10;
  background-color: transparent !important;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 32px;
  padding-bottom: 10px;
}
.headerMobile {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10;
  background-color: #ffffff !important;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding-right: 32px;
  padding-bottom: 10px;
}
.MuiInputBase-input:focus + .fixedBtn {
  display: none;
}
.fixedBtn {
  z-index: 9999 !important;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 15px;
  transition: 1s;
}
.onFocus {
  bottom: -120px;
  transition: 1s;
}
.fixedBtn button {
  width: 100%;
  margin-bottom: 10px;
}
.fixedBtn .MuiBox-root-16 {
  margin-top: 10px !important;
  margin-bottom: 8px;
}
.fixedBtn .MuiBox-root-15 {
  margin-top: 10px !important;
  margin-bottom: 8px;
}
.fixedBtn .MuiBox-root-14 {
  margin-top: 10px !important;
  margin-bottom: 8px;
}

.relleno {
  padding-top: 22px;
}
.logoMobile {
  /* position: absolute; */
  width: 110px;
  height: auto;
  /* right: 48px;
  top: 22px; */
}
.IconRegister {
  display: block;
  margin: auto;
}
.Icon-cell {
  display: block;
  margin: auto;
  margin-bottom: 15px;
}
.Icon-cell-reset-phone-mobile {
  display: block;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 35px;
}
.Icon-cell-reset-phone-desktop {
  display: block;
  margin: auto;
  margin-bottom: 32px; /* 10px */
  margin-top: 48px; /* 35px */
}
.Icon-mail {
  display: block;
  margin: auto;
  margin-bottom: 15px;
}
.MuiDrawer-paperAnchorDockedLeft {
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14) !important;
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14) !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14) !important;
}
.MuiPaper-elevation4 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
}

.MuiDivider-middle {
  background-color: #2e3e4f !important;
  opacity: 0.3 !important;
  height: 1px !important;
}

.padding-modal {
  padding-left: 200px !important;
  padding-right: 200px !important;
  background-color: #ffffff !important;
}

.noIncluir {
  padding-left: 1rem;
  padding-bottom: 0px !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: 0.4px !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.no-incluir-rest-pass-mobile {
  padding-top: 0.5rem !important;
  padding-left: 0.2rem;
  padding-bottom: 0px !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: 0.4px !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.no-incluir-rest-pass {
  padding-left: 9.5rem !important;
  padding-bottom: 1rem !important;
  padding-top: 0.5rem !important;
  font-family: inherit !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: 0.4px !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

header {
  background-color: transparent !important;
}
header .MuiAppBar-colorPrimary {
  color: #2e3e4f !important;
}
.MuiMenu-list {
  z-index: 99999 !important;
}
.panel {
  width: 100vw !important;
  padding: 20px !important;
  background-color: white;
}
.slidebar span {
  font-weight: bold !important;
}
.titleMobileProfile {
  font-weight: bold !important;
  font-size: 1.2rem !important;
}
.marginLeft {
  font-size: 1.2rem !important;
}
.btnMobileGuardar {
  width: 236px;
  height: 54px;
  border-radius: 4px;
}
.dividerMobile {
  height: 1px;
  border-bottom: solid 1px #e3e3e3;
  padding-top: 12px;
}
.sliding-panel-container.active {
  z-index: 1100 !important;
}

.btn-permission {
  width: 300 !important;
  height: 52 !important;
}

.logo {
  position: relative;
  width: 100px;
  margin-top: -130px;
  margin-right: -850px;
}

.paddingMobile {
  border-bottom: 1px solid #e3e3e3;
}
/* MOBILE ONLY */
@media only screen and (max-width: 750px) {
  .header {
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 10;
    /* background-color: #ffffff !important; */
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    padding: 32px;
  }
  .paddingMobile {
    margin-top: -8px !important;
    border-bottom: 1px solid #e3e3e3;
  }
  .main-box {
    position: absolute;
    padding-top: 0rem !important;
    top: 70px !important;
    padding-left: 32px;
    padding-right: 32px;
    background-color: #ffffff !important;
  }
  .main-boxs {
    height: 100vh;
    width: 100%;
    padding-top: 0rem !important;
    /* top: 10px !important; */
    padding-left: 32px;
    padding-right: 32px;
    background-color: #ffffff !important;
  }
  .main-boxs-inside {
    position: absolute;
    top:59%;
    left: 0%;
    transform:translate(0%, -50%);
    -webkit-transform:translate(0%, -50%);
    padding-left: 32px;
    padding-right: 32px;
  }
  .main-boxs-logo {
    position: absolute;
    padding-top: 0rem !important;
    top: 10px !important;
    padding-left: 102px;
    padding-right: 102px;
  }
  .main-box-logo {
    position: absolute;
    padding-top: 0rem !important;
    top: 70px !important;
    padding-left: 102px;
    padding-right: 102px;
  }

  .logoMobile {
    /* position: absolute; */
    width: 65px;
  }
  .IconRegister {
    display: block;
    margin: auto;
    margin-top: 40px;
  }

  .formControlCell {
    margin-right: 10px !important;
  }
  .celMobile {
    margin-left: 0px !important;
  }
  .MuiContainer-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .padding-modal {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .btnMobileGuardar {
    width: 100% !important;
    /*max-width: 311px; */
    min-height: 48px;
    position: fixed !important;
    top: 80vh !important;
    margin-left: -156px !important;
  }

  .dividerMobile {
    height: 1px;
    border-bottom: solid 1px #e3e3e3;
    padding-top: 15px !important;
  }
  body {
    background-color: #fff;
    margin: 0;
    font-family: "AvenirLTStd-Medium";
    background-image: none;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: -13rem;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }
}

.tituloModal {
  font-size: 27px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #2e3e4f !important;
}
/*
.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}
*/

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#root {
  display: flex;
}
#root > main {
  height: 100vh;
  display: flex;
  width: 100vw;
  flex-direction: column;
  /* justify-content: space-between; */
  margin: 0;
}

/* Hack para los select */
/* TODO: Borrar o comentar esta clase y ver por qué se vuelve trasparente los items */
/*
.MuiMenu-list {
  background-color: #fff;
}
*/

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #2e3e4f !important;
}
.MuiFormLabel-root.Mui-focused {
  color: #2e3e4f !important;
}

.content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* margin: 2rem 2rem; */
}
.content2 {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: -2rem -2rem;
  margin-top: 0rem;
}

.content3 {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 0rem 0em;
}
.pageTitle {
  color: #2e3e4f;
  font-size: 32px !important;
  padding-bottom: 2rem !important;
  /* padding-top: 8rem !important; */
  font-stretch: normal;
  line-height: 1.11 !important;
}
.pageTitles {
  color: #2e3e4f;
  font-size: 32px !important;
  /*padding-bottom: 2rem !important;
   padding-top: 8rem !important; */
  font-stretch: normal;
  line-height: 1.11 !important;
}
.pageTitleMobile {
  color: #2e3e4f;
  font-size: 22px !important;
  padding-bottom: 1rem !important;
  /* padding-top: 8rem !important; */
  font-stretch: normal;
  line-height: 1.11 !important;
  letter-spacing: normal;
}
.pageTitleMobiles {
  color: #2e3e4f;
  font-size: 22px !important;
  /*padding-bottom: 1rem !important;
   padding-top: 8rem !important; */
  font-stretch: normal;
  line-height: 1.11 !important;
  letter-spacing: normal;
}

.titleReset {
  font-weight: 750 !important;
  text-align: center;
  color: #2e3e4f;
  font-size: 30px !important;
  /*  padding-top: 8rem !important; */
  font-stretch: normal;
  line-height: 1.11 !important;
  letter-spacing: normal;
}
.titleResetMobile {
  text-align: center;
  color: #2e3e4f;
  font-size: 22px !important;
  /*  padding-top: 8rem !important; */
  font-stretch: normal;
  line-height: 1.11 !important;
  letter-spacing: normal;
}

.titleData {
  font-weight: 750;
  text-align: center;
  color: #2e3e4f;
  font-size: 32px !important;
  font-stretch: normal;
  line-height: 1.11 !important;
  letter-spacing: normal;
}
.titleDataMobile {
  font-weight: 750;
  text-align: center;
  color: #2e3e4f;
  font-size: 22px !important;
  font-stretch: normal;
  line-height: 1.11 !important;
  letter-spacing: normal;
}

.sectTitle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sectTitle span {
  padding-right: 1rem;
  font-weight: bold;
}
.sectTitle hr {
  border-top: 1px solid #2e3e4f;
  width: auto;
  flex: 1;
}
.m-auto {
  margin-left: auto;
  margin-right: auto;
}
.btn-popup {
  width: 120px;
  height: 48px;
}
/* ALINEACIONES DE TEXTOS (MOBILE) */
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}

.text-black {
  color: #000;
}

.img-absolute-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/* PADDING */
.pt-0 {
  padding-top: 0 !important;
}
.pt-05 {
  padding-top: 0.5rem !important;
}
.pt-1 {
  padding-top: 1rem !important;
}
.pt-2 {
  padding-top: 2rem !important;
}
.pt-3 {
  padding-top: 3rem !important;
}
.pt-4 {
  padding-top: 4rem !important;
}
.pt-5 {
  padding-top: 5rem !important;
}

.pr-0 {
  padding-right: 0 !important;
}
.pr-05 {
  padding-right: 0.5rem !important;
}
.pr-1 {
  padding-right: 1rem !important;
}
.pr-2 {
  padding-right: 2rem !important;
}
.pr-3 {
  padding-right: 3rem !important;
}
.pr-4 {
  padding-right: 4rem !important;
}
.pr-5 {
  padding-right: 5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}
.pb-05 {
  padding-bottom: 0.5rem !important;
}
.pb-1 {
  padding-bottom: 1rem !important;
}
.pb-2 {
  padding-bottom: 2rem !important;
}
.pb-3 {
  padding-bottom: 3rem !important;
}
.pb-4 {
  padding-bottom: 4rem !important;
}
.pb-5 {
  padding-bottom: 5rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}
.pl-05 {
  padding-left: 0.5rem !important;
}
.pl-1 {
  padding-left: 1rem !important;
}
.pl-2 {
  padding-left: 2rem !important;
}
.pl-3 {
  padding-left: 3rem !important;
}
.pl-4 {
  padding-left: 4rem !important;
}
.pl-5 {
  padding-left: 5rem !important;
}

/* MARGIN */
.mt-0 {
  margin-top: 0 !important;
}
.mt-05 {
  margin-top: 0.5rem !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mt-3 {
  margin-top: 3rem !important;
}
.mt-4 {
  margin-top: 4rem !important;
}
.mt-5 {
  margin-top: 5rem !important;
}

.mr-0 {
  margin-right: 0 !important;
}
.mr-05 {
  margin-right: 0.5rem !important;
}
.mr-1 {
  margin-right: 1rem !important;
}
.mr-2 {
  margin-right: 2rem !important;
}
.mr-3 {
  margin-right: 3rem !important;
}
.mr-4 {
  margin-right: 4rem !important;
}
.mr-5 {
  margin-right: 5rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-05 {
  margin-bottom: 0.5rem !important;
}
.mb-1 {
  margin-bottom: 1rem !important;
}
.mb-2 {
  margin-bottom: 2rem !important;
}
.mb-3 {
  margin-bottom: 3rem !important;
}
.mb-4 {
  margin-bottom: 4rem !important;
}
.mb-5 {
  margin-bottom: 5rem !important;
}

.ml-0 {
  margin-left: 0 !important;
}
.ml-05 {
  margin-left: 0.5rem !important;
}
.ml-1 {
  margin-left: 1rem !important;
}
.ml-2 {
  margin-left: 2rem !important;
}
.ml-3 {
  margin-left: 3rem !important;
}
.ml-4 {
  margin-left: 4rem !important;
}
.ml-5 {
  margin-left: 5rem !important;
}

/* ESTILO DE ERRORES */
/* Mensaje del error */
.error-input {
  color: #c84040;
  margin-top: 0.2rem;
  font-size: small;
}
/* Etiqueta y borde del error */
.MuiFormLabel-root.Mui-error {
  color: #c84040 !important;
}
.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #c84040 !important;
}

/* Autocomplete Dropdown BackgroundColor */
/*
.MuiAutocomplete-popper {
  background-color: #fff !important;
  border: 1px solid #e6e8ed;
}
*/

/* Hover de los link */
.MuiLink-underlineHover:hover {
  text-decoration: none !important;
}

.MuiTypography-colorPrimary {
  color: #2e3e4f !important;
}
.MuiSvgIcon-colorSecondary {
  color: #2e3e4f !important;
}

.MuiTypography-displayBlock {
  letter-spacing: 0.8px !important;
}
.error-field {
  color: #b00020 !important;
  margin-top: 0.5rem;
  font-size: small;
}
.MuiListItem-gutters {
  padding-right: 0px !important;
}
.MuiListItemText-root {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.MuiListItem-root {
  color: #a2a9b0 !important;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

/* .imageRight {
  background-image: url("/images/rightArrow.svg") !important;
  background-repeat: no-repeat !important;
  background-position-y: center !important;
  background-position-x: 15rem !important;
}

.imageRight input {
  font-family: "AvenirLTStd-Medium" !important;
  padding-left: 0.5rem !important;
  padding-right: 0 !important;
  font-style: normal !important;
  font-weight: 400 !important;
  color: #9e9e9e;
} */

.formControl input {
  font-size: small;
  background-position-x: 8px !important;
  background-repeat: no-repeat !important;
  background-position-y: center !important;

  padding-left: 0.8rem !important;
}

.flagPy {
  background-image: url("../public/images/Bitmap.svg") !important;
  background-repeat: no-repeat !important;
  background-position-y: center !important;
  background-position-x: 14px !important;
}

.flagPy input {
  font-size: small;
  font-family: inherit !important;
  padding-left: 2.5rem !important;
  padding-right: 0 !important;
  font-style: normal !important;
  font-weight: 400 !important;
  color: #000000;
}
.textInput input {
  font-size: small;
  padding-left: 1rem !important;
  padding-right: 0 !important;
}

.top-box {
  display: none;
}
.MuiInputBase-root {
  font-family: inherit;
}

/* TD - Estilos root */
.MuiTab-root {
  padding: 0px !important;
  text-transform: none !important;
  font-family: inherit !important;
  font-weight: 700 !important;
  letter-spacing: normal !important;
}

.MuiTabs-indicator {
  background-color: #2c3e50 !important;
}

.MuiTypography-body2 {
  font-family: inherit !important;
}

.MuiTouchRipple-root {
  overflow: inherit !important;
  bottom: auto !important;
  top: auto !important;
}
.MuiTypography-body1 {
  font-family: inherit !important;
}
.MuiTypography-caption {
  font-family: inherit !important;
}
.MuiFormLabel-root {
  font-family: inherit !important;
  font-size: small !important;
}
.MuiFormLabel-root-128 {
  font-family: inherit !important;
  font-size: small !important;
}
.MuiFormLabel-root-119 {
  font-family: inherit !important;
  font-size: small !important;
}

.MuiSelect-selectMenu {
  height: 0 !important;
}

.MuiSelect-outlined {
  font-size: small !important;
}

.actions {
  font-family: "AvenirLTStd-Light" !important;
  text-align: center;
  padding-bottom: 0.5rem !important ;
}
.MuiInputBase-input.Mui-disabled {
  cursor: pointer !important;
}

.MuiIconButton-root {
  color: #979797 !important;
  padding: none !important;
}
.MuiButton-contained {
  box-shadow: none !important;
}
.MuiButton-contained-198 {
  box-shadow: none !important;
}
.MuiButton-contained-152 {
  box-shadow: none !important;
}
.MuiButton-contained-243 {
  box-shadow: none !important;
}

.MuiFormHelperText-contained {
  margin-left: 5px !important;
  margin-right: 14px !important;
}
/* .activeListItemIcon {
  stroke: #2c3e50;
  fill: #2c3e50;
  color: #2c3e50 !important;
  font-weight: 750 !important;
} */

/* ESTILOS SOLO PARA DESKTOP */
@media only screen and (min-width: 768px) {
  
  body {
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 0;
  }
  .top-box {
    min-width: 768px;
    max-width: 947px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
  .top-box .pageTitle {
    font-size: 1.75rem;
  }
  .top-box img {
    height: 2.3rem;
  }

  .main-box {
    min-width: 768px;
    max-width: 947px;
    /*height: 663px;*/
    border-radius: 8px;
    background-color: #ffffff;
    padding: 4rem;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .main-boxs {
    min-width: 768px;
    max-width: 947px;
    /*height: 663px;*/
    border-radius: 8px;
    background-color: #ffffff;
    padding-left: 4rem;
    padding-right: 4rem;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .main-box.simple {
    background-color: transparent !important;
    border-radius: 0;
    box-shadow: none;
  }
  .d-none {
    display: none;
  }

  .d-mb-0 {
    margin-bottom: 0 !important;
  }

  .d-pl-0 {
    padding-left: 0 !important;
  }
  .d-pl-05 {
    padding-left: 0.5rem !important;
  }
  .d-pl-1 {
    padding-left: 1rem !important;
  }
  .d-pl-2 {
    padding-left: 2rem !important;
  }
  .d-pl-3 {
    padding-left: 3rem !important;
  }
  .d-pl-4 {
    padding-left: 4rem !important;
  }
  .d-pl-5 {
    padding-left: 5rem !important;
  }

  .d-pr-1 {
    padding-right: 1rem !important;
  }
  .d-pr-2 {
    padding-right: 2rem !important;
  }
  .d-pr-3 {
    padding-right: 3rem !important;
  }
  .d-pr-4 {
    padding-right: 4rem !important;
  }
  .d-pr-5 {
    padding-right: 5rem !important;
  }

  /* ALINEACIONES DE TEXTOS (DESKTOP) */
  .d-text-left {
    text-align: left;
  }
  .d-text-center {
    text-align: center;
  }
  .d-text-right {
    text-align: right;
  }
}